.about-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  h2 {
    margin-top: 30px;
    color: #c99377;
  }
  
  p {
    margin: 10px 0;
  }
  
  @media (max-width: 768px) {
    .about-container {
      padding: 10px;
    }
  
    h1 {
      font-size: 1.5em;
    }
  
    h2 {
      font-size: 1.2em;
    }
  
    p {
      font-size: 0.9em;
    }
  }
  