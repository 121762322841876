.gallery-container {
  position: relative;
  padding: 20px;
}

.upload-button {
  margin-bottom: 20px;
}

.ant-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ant-upload {
  width: 100%;
}

.ant-upload-list-picture-card-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
}

.gallery-item {
  flex: 1 1 auto;
  min-width: 200px;
  max-width: 25%;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}

.gallery-item img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.gallery-item img:hover {
  transform: scale(1.05);
}

.most-engaged {
  flex: 2 2 auto;
}

.engagement {
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: flex;
  gap: 10px;
}

.comments {
  padding: 10px;
}

.comments .ant-comment-content-author-name {
  font-weight: bold;
}

.comments .ant-input {
  margin-top: 10px;
}

@media (max-width: 768px) {
  .gallery-item {
    max-width: 100%;
  }
}

.ant-progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
