/* src/components/Events/Events.css */
.events {
    padding: 20px;
    /* background-color: var(--background-color); */
    color: var(--primary-color);
    font-family: Arial, sans-serif;
  }
  
  .events h1 {
    text-align: center;
    color: var(--primary-color);
  }
  
  .timer {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .timer h2 {
    color: var(--background-color);
  }
  
  .events-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .event {
    background-color: #262626;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    display: flex;
    flex-direction: row;
  }
  
  .event.finished {
    opacity: 0.6;
  }
  
  .event h2 {
    margin: 0 0 10px;
    color: var(--secondary-color);
  }

  .event h3 {
    color: var(--secondary-color);
  }
  
  .event p {
    margin: 5px 0;
  }
  
  .tag {
    background-color: var(--primary-color);
    color: var(--background-color);
    padding: 5px 10px;
    border-radius: 3px;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .scrolling-poem {
    margin-top: 30px;
    padding: 20px;
    background-color: #262626;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    height: 50px;
    position: relative;
  }
  
  .scrolling-poem p {
    position: absolute;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    overflow: hidden;
    animation: scroll 15s linear infinite;
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  @media (max-width: 768px) {
    .events-list {
      gap: 15px;
    }
  
    .event {
      padding: 10px;
    }
  }
  