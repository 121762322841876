:root {
  --primary-color: #c99377;
  --secondary-color: #ffffff;
  --background-color: #161616;
  --button-hover-color: #ffffff;
}

.header {
  position: relative;
  /* height: max-content; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--secondary-color);
  z-index: 10;
  width: 100vw;
  background-color: var(--secondary-color);
  transition: opacity 0.5s ease, visibility 0.5s ease;
}

.fade-in {
  opacity: 1;
  visibility: visible;
}

.fade-out {
  opacity: 0;
  visibility: hidden;
}

.container {
  width: 80vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo a {
  color: var(--primary-color);
  text-decoration: none;
  font-size: 1.5em;
  font-weight: bold;
}

.nav ul {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
  width: max-content;
  margin-right: 10vw;
}

.nav ul li a {
  color: var(--primary-color);
  text-decoration: none;
}

.nav ul li a:hover {
  color: var(--primary-color);
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger .line{
  background-color: var(--primary-color);
}

.hamburger.open .line:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.open .line:nth-child(2) {
  opacity: 0;
}

.hamburger.open .line:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

.line {
  width: 25px;
  height: 3px;
  background-color: var(--secondary-color);
  margin: 2px 0;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .cover-content {
    align-items: center;
    text-align: center;
  }

  .cover-content img {
    width: 60vw;
  }

  .cover-section {
    background: url('../../../public/images/cover.png') no-repeat center center
      fixed;
    background-size: cover;
    padding: 8vw;
    justify-content: center;
    align-items: flex-end;
  }

  .action-buttons .button {
    padding: 15px 20px;
    margin: 10px 0;
  }
}

@media (max-width: 440px) {
  .nav {
    position: absolute;
    top: 0;
    right: 0;
    padding-top: 20vw;
    background-color: var(--background-color);
    height: 100vw;
    width: 100vw;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
  }

  .nav.open {
    transform: translateX(0);
  }

  .nav ul {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
  }

  .nav ul li {
    color: var(--secondary-color);
    text-decoration: none;
    padding: 20px;
  }

  .hamburger {
    display: flex;
  }
}
