.contributions {
    padding: 20px;
    background-color: var(--background-color);
    color: var(--secondary-color);
    font-family: Arial, sans-serif;
    text-align: center;
  }
  
  .contributions h1 {
    color: var(--primary-color);
    margin-bottom: 20px;
  }
  
  .contribution-details {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .contribution-item {
    background-color: #262626;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .contribution-item h2 {
    color: var(--primary-color);
    margin-bottom: 10px;
  }
  
  .contribution-item p {
    margin: 5px 0;
  }
  
  .contribution-item a {
    color: var(--link-color);
    text-decoration: none;
  }
  
  .contribution-item a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .contribution-details {
      gap: 15px;
    }
  
    .contribution-item {
      padding: 10px;
    }
  }
  