.message-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.message-form input,
.message-form textarea,
.message-form select,
.message-form button {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1em;
}

.message-form input:focus,
.message-form textarea:focus,
.message-form select:focus,
.message-form button:focus {
  outline: none;
  border-color: var(--primary-color);
}

.input-heading,
.input-name {
  margin-bottom: 10px;
}

.input-signoff {
  margin-bottom: 10px;
}

.message-form button {
  background-color: var(--primary-color);
  color: white;
  cursor: pointer;
  border: none;
}

.message-form button:hover {
  background-color: darken(var(--primary-color), 10%);
}

.message-form .error {
  color: red;
  margin-top: 10px;
}
