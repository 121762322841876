:root {
  --primary-color: #c99377;
  --secondary-color: #ffffff;
  --background-color: #161616;
  --button-hover-color: #ffffff;
}

.message-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 0.5vw;
}

.message-card {
  border-radius: 1.5vw;
  cursor: pointer;
  position: relative;
  transition: transform 0.2s;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  overflow: hidden;
  border-width: 2px;
}

.message-card:hover {
  transform: translateY(-5px);
}

.message-content {
  padding: 2.5vw;
}

.message-footer {
  background-color: var(--secondary-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.modal-content {
  padding: 20px;
}

.main-message {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.comment-list {
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.reply-comment {
  margin-left: 20px;
}

.reply-form, .comment-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}


@media (max-width: 768px) {
  .message-grid {
    grid-template-columns: repeat(auto-fill, minmax(1800px, 1fr));
  }
}

@media (max-width: 440px) {
  .message-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}