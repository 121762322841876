:root {
  --primary-color: #c99377;
  --secondary-color: #ffffff;
  --background-color: #161616;
  --button-hover-color: #ffffff;
}

.home {
  font-family: Arial, sans-serif;
  color: var(--secondary-color);
}

.cover-section {
  background: url('../../../public/images/cover.png') no-repeat center center fixed;
  background-size: cover;
  text-align: center;
  padding: 0;
  height: 70vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10vw;
  color: var(--secondary-color);
  position: relative;
}

.cover-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  border-radius: 10px;
}

.cover-content img {
  width: 40vw;
}

.cover-photo {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-top: 20px;
}

.sub-header {
  margin-top: 10px;
  font-size: 1.5em;
  color: var(--primary-color);
}

.action-buttons {
  margin-top: 20px;
}

.action-buttons .button {
  color: black;
  padding: 20px 20px;
  margin: 5px;
  text-decoration: none;
  font-weight: bold;
}

.action-buttons .button.btn-primary {
  background-color: var(--primary-color);
}

.action-buttons .button.btn-secondary {
  background-color: var(--secondary-color);
}

.action-buttons .button:hover {
  background-color: var(--button-hover-color);
}

.next-event {
  background: var(--secondary-color);
  color: var(--secondary-color);
}

.next-event-details{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.event-date {
  background-color: var(--primary-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  text-align: center;
  font-size: 3em;
  color: var(--secondary-color);
  padding: 0 5svw;
  height: 25vw;
  width: 20vw;
}

.event-date h2 {
  margin: 0;
  color: var(--secondary-color);
}

.event-date h3 {
  margin: 0;
}

.event-details {
  flex: 3;
  text-align: left;
  padding: 0 5vw;
  background-color: var(--background-color);
  color: var(--secondary-color);
  height: 25vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.event-details h2 {
  margin: 0;
  color: var(--primary-color);
}

.event-details p {
  margin: 5px 0;
}

.countdown {
  color: var(--primary-color);
  font-size: 1.5em;
  text-align: center;
  padding: 1vw;
  margin: 0px;
}

.tribute-button {
  padding: 2.5vw 0;
  display: flex;
  flex-direction: row;
  align-items: end;
  height: fit-content;
  gap: 1vw;
  color: var(--background-color);
  justify-content: space-between;
  align-items: center;
}

.tribute-button button {
  border-radius: 0;
  border: 0;
  padding: 1.5vw;
  height: fit-content;
  background-color: var(--primary-color);
  color: var(--secondary-color);
}

.message-section {
  background: url('../../../public/images/MessageCover.png') no-repeat center center
    fixed;
  background-size: cover;
  padding: 0 2vw;
}

.message-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
}

.message-card {
  background: var(--background-color);
  color: var(--secondary-color);
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  padding: 20px;
  transition: transform 0.3s;
  cursor: pointer;
}

.message-card:hover {
  transform: scale(1.05);
}

.message-details {
  max-width: 800px;
  margin: 0 auto;
}

.message-details h2 {
  font-size: 2em;
  color: var(--primary-color);
}

.comment-list {
  margin-top: 20px;
}

.message-form {
  margin: 20px 0;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5vw;
}

.message-form textarea {
  max-width: 600px;
  padding: 10px;
  height: 80px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid var(--primary-color);
  color: var(--secondary-color);
}

.message-form button {
  padding: 10px 20px;
  background-color: var(--background-color);
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.message-form button:hover {
  background-color: var(--link-hover-color);
}

.message-form .error {
  color: red;
}

.links {
  background: #111;
  padding: 20px;
  text-align: center;
}

.links ul {
  list-style-type: none;
  padding: 0;
}

.links li {
  display: inline;
  margin: 0 10px;
}

.links a {
  text-decoration: none;
  color: var(--link-color);
}

.links a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .cover-content {
    align-items: center;
    text-align: center;
  }

  .cover-content img {
    width: 80vw;
  }

  .cover-section {
    background: url('../../../public/images/cover.png') no-repeat -55vw center;
    background-size: cover;
    padding: 5vw;
    justify-content: center;
    align-items: end;
  }

  .action-buttons .button {
    padding: 10px 15px;
    margin: 10px 0;
  }

  .next-event {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .event-date {
    font-size: 1.5em;
  }

  .event-details {
    text-align: center;
    
    font-size: 0.5em;
  }
}


@media (max-width: 368px) {
  .cover-section {
    background: url('../../../public/images/cover.png') no-repeat -220vw center;
    background-size: cover;
    padding: 5vw;
    justify-content: center;
    align-items: end;
  }
}
